import React, { FC } from "react"
import type { HeadFC } from "gatsby"
import { graphql, PageProps } from "gatsby";
import { BaseLayout } from "@layouts";
import { SocialMedia, SponsoredContainer, VideoBackground } from "@components";
import Logo from "@icons/logo.svg"
import { BlazeIt, CanadaPosters } from "../releases/partykrasher";
import TamaLogo from "@icons/tama.svg"
import OrangeLogo from "@icons/orange.svg"
import "./index.scss"
import { Endorsements, ReleaseFestival, Rockwave } from "../releases/restless";


type IndexPageData = Queries.IndexPageQuery
type IndexPageContext = {}

const IndexPage: FC<PageProps<IndexPageData, IndexPageContext>> = ( { data } ) => {
    function setPosterImage( id ) {
        return data.videoThumbs.nodes.find( node => node.name === id ).childImageSharp.gatsbyImageData
    }

    return (
        <BaseLayout>
            <VideoBackground videoId={ 's2hbYLI9Vys' } title={ "Okeanos" } posterImage={ setPosterImage( 's2hbYLI9Vys' ) }/>
            <div className={ 'container box text-center flow' } data-container-size={ 'sm' } data-box-padding={ 'lg' }>
                <Logo className={ "logo" }/>
                <SocialMedia iconSize={ 'lg' }/>
                <p>Maplerun is a modern rock band based in Europe. The group got together in 2007 in a collective effort of a bunch of friends who wanted to express their emotions through music. A
                    four piece
                    band that combines music styles such as nu-metal, modern rock & alternative rock, reflecting the various influences of the band members. Maplerun’s major influences are Metallica,
                    System of a Down, Godsmack, Deftones, Tool, Bring Me The Horizon, Katatonia & Porcupine Tree among other great bands.</p>
            </div>

            <ReleaseFestival poster={ data.releaseFestivalPhoto! }/>

            <CanadaPosters posters={ data.canadaPosters! }/>

            <Rockwave poster={ data.rockwave?.childImageSharp?.gatsbyImageData! }/>

            <VideoBackground videoId={ 'mGexPFV2Xo0' } title={ "Let's Rock" } posterImage={ setPosterImage( 'mGexPFV2Xo0' ) }/>
            <BlazeIt posters={ data.blazeItPosters! }/>
            <VideoBackground videoId={ 'nOOfP1Sjq5k' } title={ "ERT3 LiveTV – “VOLUME”" } posterImage={ setPosterImage( 'nOOfP1Sjq5k' ) }/>

            <SponsoredContainer
                logo={ <TamaLogo/> }
                image={ data.joTama?.childImageSharp?.gatsbyImageData! }
                name={ "Chris Achladiotis" }
                aka={ "Boola" }
            />

            <Endorsements logos={ data.endorsement! }/>

            <SponsoredContainer
                logo={ <OrangeLogo/> }
                image={ data.sitisOrange?.childImageSharp?.gatsbyImageData! }
                name={ "Orestis Sitis" }
                aka={ "Giannakis" }
                placement={ 'center' }
            />

            <hr className={ 'divider' }/>
            <div className={ 'container box text-center' } data-container-size="sm">
                <h3 className={ "h6 font-family:display" }>“BOMBS” OFFICIAL MUSIC VIDEO 2014</h3>
                <p>sponsored by RedBull</p>
            </div>
            <hr className={ 'divider' }/>
            <VideoBackground videoId={ 'BQH5T9aO8lk' } title={ "Bombs" } posterImage={ setPosterImage( 'BQH5T9aO8lk' ) }/>
        </BaseLayout>
    )
}

export default IndexPage

export const Head: HeadFC = () => (
    <>
        <title>Home Page</title>
    </>
)


export const query = graphql`
    query IndexPage {
        canadaPosters: allFile(filter: {relativePath: {in: ["homepage/rocklahoma.jpg", "homepage/canada.jpg", "homepage/10-year-ann.jpg"]}}) {
            nodes {
                childImageSharp {
                    gatsbyImageData
                }
            }
        }
        releaseFestivalPhoto: file(relativePath: {eq: "homepage/release-festival-photo.jpg"}) {
            childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
            }
        }
        joTama: file(relativePath: {eq: "homepage/jo-tama.jpg"}) {
            childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
            }
        }
        sitisOrange: file(relativePath: {eq: "homepage/siten-orange.jpg"}) {
            childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
            }
        }
        rockwave: file(relativePath: {eq: "homepage/rockwave.jpg"}) {
            childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
            }
        }
        blazeItPosters: allFile(filter: {relativePath: {in: ["homepage/bayley-tour.jpg", "homepage/canada-1.jpg"]}} ) {
            nodes {
                childImageSharp {
                    gatsbyImageData
                }
            }
        }
        endorsement: allFile(filter: {relativePath: {in: ["homepage/pavement.png", "homepage/unity-logo.jpg"]}} ) {
            nodes {
                childImageSharp {
                    gatsbyImageData
                }
            }
        }
        videoThumbs: allFile(filter: {relativePath: {in: ["video-thumbnails/s2hbYLI9Vys.jpg", "video-thumbnails/mGexPFV2Xo0.jpg", "video-thumbnails/nOOfP1Sjq5k.jpg", "video-thumbnails/BQH5T9aO8lk.jpg"]}} ) {
            nodes {
                name
                childImageSharp {
                    gatsbyImageData
                }
            }
        }
    }`
